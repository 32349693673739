'use strict'

import * as logger from '@akerolabs/framework/src/objects/logger.js'
import ensure from '@akerolabs/framework/src/methods/ensure.js'

/**
 * @type {Array}
 * @description Since the forms will not have been initialized/bootstrapped
 * when any of the $forms.on events get bound, we are going to push them into
 * and array and listen for the form bootstrap events, which will then take the
 * stored events and bind them to the forms.
 */
const boundEvents = []

/**
 * @type {Boolean}
 * @description When adding the form events to the page, the first time we try
 * to add something we will bind a event handler for when forms are added to the
 * library, and this will be set to true, to prevent that event being setup more
 * than once. This event will allow all the events already defined to be added to
 * any new forms that are added to the page.
 */
let bootBound = false

/**
 * @param {Function} methodToRun
 * @description Simple shortcut method for executing a callback within a try
 * catch block
 */
const tryAction = (methodToRun) => {
  try {
    return methodToRun()
  } catch (ex) {
    logger.error(ex)
    return null
  }
}

/**
 * @description Expose a $forms object that will allow a simple interface for
 * binding event handlers to any bootstrapped form that is in the page.
 */
ensure(window, '$forms', () => ({
  boundEvents,
  bootBound,

  /**
   * @param {String} eventName
   * @param {Function} callbackMethod
   * @description Simplified method to bind event listeners to all forms
   * currently in the page
   */
  on: (eventName, callbackMethod) => {
    tryAction(() => {
      const forms = $ak.get('forms')
      const list = forms.list()

      //We need to push the event into the boundEvents array this is so we
      // can add any events to forms that get bootstrapped later in the page
      // load.
      boundEvents.push({ eventName, callbackMethod })

      // If we have not yet bound the form listener for the bootstrap event we
      // will need to set it, this is to ensure events are correctly bound if
      // this method is run before any forms are ready
      if (!bootBound) {
        bootBound = true
        forms.on('forms::add', (form) => {
          boundEvents.forEach((evt) => {
            form.on(evt.eventName, evt.callbackMethod)
          })
        })
      }

      // Now we will try to iterate over any bootstrapped forms and add the new
      // events to them
      list.forEach((form) => {
        form.on(eventName, callbackMethod)
      })
    })
  },

  /**
   * @param {String} eventName
   * @param {Function} callbackMethod
   * @description Simplified method to un bind event listeners to all forms
   * currently in the page
   */
  off: (eventName, callbackMethod) => {
    tryAction(() => {
      const list = $ak.get('forms').list()
      // We need to check if the bound event is stored in the bound events array
      // if it is we should have a non -1 array index
      const idx = boundEvents.findIndex((i) => i.eventName === eventName)

      // If an event was found in the array of stored events then we will have
      // to splice it out so it won't be added to any new bootstrapped forms
      if (idx !== -1) boundEvents.splice(idx, 1)

      // Now we have removed it from all the forms that are currently active in
      // the page we can un-bind them from all the active form objects also
      list.forEach((form) => {
        form.off(eventName, callbackMethod)
      })
    })
  },
}))

/**
 * @description expose an $events object that will allow for cross browser
 * supported event listeners
 */
ensure(window, '$events', () => ({
  /**
   * @param {Object} object
   * @param {String} eventName
   * @param {Function} callbackMethod
   * @param {Boolean} options
   * @return {Object} token
   * @description Simplified method to bind event listeners to all forms
   * currently in the page
   */
  add: (obj, eventName, callbackMethod, options = false) =>
    tryAction(() =>
      $ak.get('listeners').add(obj, eventName, callbackMethod, options),
    ),

  /**
   * @param {String} eventName
   * @param {Function} callbackMethod
   * @description Simplified method to bind event listeners to all forms
   * currently in the page
   */
  remove: (tokenObject) =>
    tryAction(() => $ak.get('listeners').remove(tokenObject)),
}))

/**
 * @description Expose a method that will grab all of the account variables
 * from the active forms currently in the page
 */
ensure(
  window,
  '$variables',
  () => () =>
    tryAction(() => {
      const { extend } = $ak.get('helpers')
      const { list } = $ak.get('forms')
      const mapFn = (i) => i.opts.variables
      const reduceFn = (a, b) => extend(a, b)
      return list().map(mapFn).reduce(reduceFn, {})
    }),
)

/**
 * @description Expose a method that will return the url parameters
 */
ensure(
  window,
  '$params',
  () => () =>
    tryAction(() =>
      $ak.get('helpers').parseQueryParams(window.location.search),
    ),
)

/**
 * @description Expose a method for extending objects
 */
ensure(
  window,
  '$extend',
  () =>
    (a, b, newObj = false) =>
      tryAction(() => $ak.get('helpers').extend(a, b, newObj)),
)

/**
 * @description Expose a method to copy an object
 */
ensure(
  window,
  '$copy',
  () => (a) => tryAction(() => $ak.get('helpers').copy(a)),
)

/**
 * @description Expose a $elements object that will allow a simple interface
 * for manipulating dom elements
 */
ensure(
  window,
  '$elements',
  () => (selector) => tryAction(() => $ak.get('elements')(selector)),
)

/**
 * @description Expose the DOM ready method
 */
ensure(window, '$domReady', () => (fn) => {
  tryAction(() => $ak.ready(fn))
})

/**
 * @description Expose the Date constructor
 */
ensure(
  window,
  '$date',
  () =>
    (...args) =>
      tryAction(() => {
        const d = $ak.get('Date')
        return new d(...args)
      }),
)

/**
 * @description Expose the logging object
 */
ensure(window, '$logger', () => logger)
