'use strict'

export default {
  name: 'banner',
  instantiate: false,
  factory: [
    'listeners',
    'elements',
    'types',
    function (listeners, elements, types) {
      return {
        /**
         * Simple banner slider
         * @param {HTMLNode} elem - The html element that will be out slider
         * @class Slider
         */
        constructor(elem) {
          const autoplay = elem.getAttribute('data-autoplay') === 'true'
          const actions = elem.getAttribute('data-actions') === 'true'
          const dots = elem.getAttribute('data-dots') === 'true'
          const dotsPos = elem.getAttribute('data-dots-position') || 'inside'
          const speed = (Number(elem.getAttribute('data-speed')) || 5) * 1000
          const animationType = elem.getAttribute('data-animation') || 'slide'
          const animationSpeed =
            (Number(elem.getAttribute('data-animation-speed')) || 1) * 1000
          this.intervalSpeed = speed
          this.interval = null
          this.elem = elem
          this.list = elements('ul', this.elem)[0]
          this.autoplay = autoplay
          this.actions = actions
          this.dots = dots
          this.dotsPos = dotsPos
          this.animationType = animationType
          this.animationSpeed = `${animationSpeed}ms`
          this.currentSlide = 0
          this.getSlides()
          this.makeActions()
          this.makeDots()
          this.setHeight()
          this.setAnimationSpeed()
          if (this.animationType !== 'fade') this.setWidth()
          if (this.dots) this.setActiveDot(this.currentSlide)
          if (this.autoplay) this.play()
          listeners.add(window, 'resize', () => {
            this.setHeight()
            if (this.animationType !== 'fade') this.setWidth()
            this.move(this.currentSlide, false)
          })
        },

        /**
         * Finds all of the slides within the slider element
         * @memberof Slider
         * @api private
         */
        getSlides() {
          this.slides = elements('ul li', this.elem)
          this.slidesCount = this.slides.length
        },

        /**
         * Creates the action buttons and adds the to the DOM
         * @memberof Slider
         * @api private
         */
        makeActions() {
          if (this.actions) {
            const next = this.makeAction('next')
            const prev = this.makeAction('prev')
            const parent = this.list.parentNode
            parent.insertBefore(prev, this.list)
            parent.insertBefore(next, this.list)
          }
        },

        /**
         * Creates an action button and attaches the event listeners
         * @param  {String} type - The name of the action
         * @return {HTMLNode}
         * @memberof Slider
         * @api private
         */
        makeAction(type) {
          const action = document.createElement('a')
          // action.innerHTML = type;
          action.className = `c-slider__action c-slider__action--${type}`
          listeners.add(action, 'click', () => {
            const fn = this[type]
            this.stop()
            if (types.isFunction(fn)) fn.call(this)
            this.play()
            return false
          })
          return action
        },

        /**
         * Makes the dot navigation for the slider
         * @memberof Slider
         * @api private
         */
        makeDots() {
          if (this.dots) {
            const cont = document.createElement('ul')
            cont.className = 'c-slider__dots'
            for (let i = 0, l = this.slidesCount; i < l; i++) {
              const li = this.makeDot(i)
              cont.appendChild(li)
            }
            if (this.dotsPos === 'inside') this.elem.appendChild(cont)
            else this.elem.parentNode.appendChild(cont)
          }
        },

        /**
         * Makes the dot item an returns it
         * @param  {Number} num - The index of the slide
         * @return {HTMLNode}
         * @memberof Slider
         * @api private
         */
        makeDot(num) {
          const li = document.createElement('li')
          const a = document.createElement('a')
          listeners.add(a, 'click', () => {
            this.stop()
            this.moveTo(num)
            this.play()
            return false
          })
          li.appendChild(a)
          return li
        },

        /**
         * Sets the active dot in the dot navigation
         * @param {Number} num - The index of the slide
         * @memberof Slider
         * @api private
         */
        setActiveDot(num) {
          const dots = elements('.c-slider__dots a', this.elem.parentNode)
          for (let i = 0, l = dots.length; i < l; i++) {
            if (i === num) dots[i].className = 'is-active'
            else dots[i].className = ''
          }
        },

        /**
         * Sets the height of the slider element
         * @memberof Slider
         * @api private
         */
        setHeight() {
          let max = 0
          for (let i = 0, l = this.slidesCount; i < l; i++) {
            const h = this.slides[i].offsetHeight
            if (h > max) max = h
          }
          this.elem.style.minHeight = `${max}px`
        },

        /**
         * Sets the height of the slider element
         * @memberof Slider
         * @api private
         */
        setAnimationSpeed() {
          if (this.animationType === 'fade') {
            for (let i = 0, l = this.slidesCount; i < l; i++) {
              this.slides[i].style.transitionDuration = this.animationSpeed
            }
          } else {
            this.list.style.transitionDuration = this.animationSpeed
          }
        },

        /**
         * Sets the width of each slide and the list element
         * @memberof Slider
         * @api private
         */
        setWidth() {
          const width = this.elem.offsetWidth
          for (let i = 0, l = this.slidesCount; i < l; i++) {
            this.slides[i].style.width = `${width}px`
          }
          this.list.style.width = `${this.slidesCount * width}px`
        },

        /**
         * Method to show the next slide
         * @memberof Slider
         * @api public
         */
        next() {
          if (this.currentSlide < this.slidesCount - 1) this.currentSlide++
          else this.currentSlide = 0
          this.move(this.currentSlide, true)
        },

        /**
         * Method to show the previous slide
         * @memberof Slider
         * @api public
         */
        prev() {
          if (this.currentSlide > 0) this.currentSlide--
          else this.currentSlide = this.slidesCount - 1
          this.move(this.currentSlide, true)
        },

        /**
         * Moves to a specific slide
         * @param  {Number} num - The slide number to move to
         * @memberof Slider
         * @api public
         */
        moveTo(num) {
          this.currentSlide = Number(num)
          this.move(this.currentSlide, true)
        },

        /**
         * Makes the slider cycle through the slides on a set interval
         * @memberof Slider
         * @api public
         */
        play() {
          this.interval = setInterval(this.next.bind(this), this.intervalSpeed)
        },

        /**
         * Clears the interval and stops the slider cylcling through slides
         * @memberof Slider
         * @api public
         */
        stop() {
          clearInterval(this.interval)
          this.interval = null
        },

        /**
         * Moves the slides by adjdusting the transform
         * @param {Number} curr
         * @memberof Slider
         * @api public
         */
        move(curr) {
          switch (this.animationType) {
            case 'fade':
              this.fade(curr)
              break
            case 'slide':
              this.slide()
              break
          }
          if (this.dots) {
            this.setActiveDot(curr)
          }
        },

        /**
         * Runs the slide transition
         * @memberof Slider
         * @api public
         */
        fade(curr) {
          for (let i = 0; i < this.slides.length; i += 1) {
            if (i === curr) {
              this.slides[i].style.opacity = 1
              this.slides[i].style.zIndex = 1
            } else {
              this.slides[i].style.opacity = 0
              this.slides[i].style.zIndex = 0
            }
          }
        },

        /**
         * Runs the slide transition
         * @memberof Slider
         * @api public
         */
        slide() {
          const offset = this.currentSlide * this.elem.offsetWidth
          this.list.style.transform = `translateX(${-offset}px)`
        },
      }
    },
  ],
  postInstall: [
    'elements',
    function (elements) {
      elements('[data-ak-slider]').each((elem) => {
        const Slider = this
        new Slider(elem)
        elements(elem).removeClass('is-loading')
      })
    },
  ],
}
