'use strict'

export default {
  name: 'preview_bar',
  instantiate: false,
  factory: [
    'helpers',
    'elements',
    'logger',
    function (helpers, elements, logger) {
      const svgString =
        '<div><svg width="30" height="30" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M576 1152v128h-128v-128h128zm0-768v128h-128v-128h128zm768 0v128h-128v-128h128zm-1024 1023h384v-383h-384v383zm0-767h384v-384h-384v384zm768 0h384v-384h-384v384zm-256 256v640h-640v-640h640zm512 512v128h-128v-128h128zm256 0v128h-128v-128h128zm0-512v384h-384v-128h-128v384h-128v-640h384v128h128v-128h128zm-768-768v640h-640v-640h640zm768 0v640h-640v-640h640z"/></svg></div>'

      const clickHandler = function () {
        try {
          this.parentNode.removeChild(this)
        } catch (ex) {
          logger.error(ex)
        }
      }

      const cssObject = {
        position: 'relative',
        color: '#fff',
        padding: '5px 10px',
        fontWeight: 'bold',
        backgroundColor: '#00BEEC',
        cursor: 'pointer',
      }

      const cssQR = {
        diaplay: 'none',
        position: 'absolute',
        top: '100%',
        right: '10px',
        backgroundColor: '#fff',
        padding: '10px',
        zIndex: '999',
        fontWeight: 'normal',
        fontSize: '13px',
        color: '#4e4e4e',
        width: '220px',
        textAlign: 'center',
      }

      const iconCSS = {
        display: 'block',
        margin: '-3px 0 0 0',
        float: 'right',
        fill: '#fff',
      }

      return {
        constructor(elem) {
          elem.prepend(this.message.call(this)[0])
        },

        message() {
          const className = 'u-display-none'
          // The message bar containing all elements
          const bar = elements(document.createElement('div'))
            .attr('id', 'messageBar')
            .text('Published Preview')
            .on('click', clickHandler)
            .css(cssObject)

          const qr = elements(
            '<div><img id="qr" /><div>Scan this on your ' +
              'smart phone to preview the page there</div></div>',
          )
            .addClass(className)
            .css(cssQR)
            .on('click', function (e) {
              helpers.stopPropagation(e)
            })

          const icon = elements(svgString)
            .css(iconCSS)
            .on('click', (e) => {
              helpers.stopPropagation(e)
              if (qr.hasClass(className)) {
                qr.removeClass(className)
              } else {
                qr.addClass(className)
              }
            })

          icon.appendTo(bar)
          qr.appendTo(bar)

          return bar
        },
      }
    },
  ],
  postInstall: [
    'elements',
    'helpers',
    'scripts',
    function (elements, helpers, scripts) {
      const path = '//cdnjs.cloudflare.com/ajax/libs/qrious/4.0.2/qrious.min.js'
      const query = helpers.parseQueryParams(window.location.href)
      if (!query.ak_test) return false
      return scripts.load(path, () => {
        const body = elements('body')
        new this(body)
        if (QRious) {
          new QRious({
            size: 200,
            element: document.getElementById('qr'),
            value: window.location.href,
          })
        }
      })
    },
  ],
}
