'use strict'

import './polyfills/md5.js'
import ak from '@akerolabs/framework'
import banner from './plugins/banner.js'
import preview from './plugins/preview_bar.js'
import '../simple-api/index.js'

const { search } = window.location
const { object } = ak.get('is')
const { parseQueryParams } = ak.get('helpers')
const logger = ak.get('logger')
const forms = ak.get('forms')
const params = parseQueryParams(search)

ak.extend(banner)
ak.extend(preview)
ak.ensure(window, '$ak', () => ak)

if (object(params) && params.preview === 'true') {
  forms.on('forms::bootstrap', ({ id }) => {
    const form = forms.get(id)
    form.submit = () => {
      logger.log('Form submit is locked in previews')
    }
  })
}
